<template>
  <v-sheet elevation="5" class="my-7 py-10">
    <h5 class="py-3 choose-title">Why Consider Us?</h5>

    <div class="d-flex align-center">
      <v-slide-group>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 text-body-1">
              We use problem-solving techniques and varied approaches gets you
              results. What results do you want? Let us get you your desired
              outcome.
            </p>
          </v-card>
        </v-slide-group-item>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 pa-3 text-body-1 d-flex align-center">
              Why treat what is undiagnosed? Here at Lifeline we diagnose your
              challenges and implement the right solutions to address them.
            </p>
          </v-card>
        </v-slide-group-item>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 pa-3 text-body-1 d-flex align-center">
              We Partner with diverse educational professionals to deliver
              quality. We bring in professionals of every kind to help you
              achieve your desire.
            </p>
          </v-card>
        </v-slide-group-item>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 pa-3 text-body-1 d-flex align-center">
              Choose from our offers ~ we have a package for every need and
              budget.
            </p>
          </v-card>
        </v-slide-group-item>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 pa-3 text-body-1 d-flex align-center">
              We provide your ward the right assistance and guidance to develop.
            </p>
          </v-card>
        </v-slide-group-item>
        <v-slide-group-item>
          <v-card
            elevation="7"
            class="pa-3 ma-lg-5 ma-3 d-flex align-center justify-center"
            :width="mobile ? '60%' : '70dvw'"
          >
            <p class="pa-lg-8 pa-3 text-body-1 d-flex align-center">
              We have helped hundreds of students and counting. Become part of
              our success.
            </p>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "Choose Us",
  data() {
    return {
      mobile: false,
    };
  },
};
</script>

<style scoped></style>
